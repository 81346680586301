import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button, Spin, List } from "antd";
import {
  SearchOutlined,
  MedicineBoxOutlined,
  PhoneFilled,
  MailFilled,
  HomeFilled,
} from "@ant-design/icons";
import { groupBy } from "lodash";
import { TimeTable } from "./TimeTable";

const RehabCoursesModal = ({ id, t }) => {
  const [association, handleAssociation] = useState({ data: null, err: false });
  const [visible, handleVisible] = useState(false);

  useEffect(() => {
    getAssociation();
  }, []);

  const getAssociation = async () => {
    try {
      const res = await axios.get(`/associations/${id}`);
      handleAssociation({ data: res.data, err: false });
    } catch (err) {
      handleAssociation({ data: null, err });
    }
  };

  const getFormatedData = () => {
    if (!association.data) return [];
    const group = groupBy(
      association.data.rehab_trainings,
      (el) => el.location.id
    );
    let d = [];
    Object.keys(group).forEach((key) =>
      d.push({ location: group[key][0].location, groupData: group[key] })
    );
    return d;
  };

  return (
    <div>
      <Button
        className=" mt-2 w-full flex items-center"
        style={{ height: "55px" }}
        onClick={() => handleVisible(true)}
      >
        <SearchOutlined />
        <span className="white-space">
          Zeige alle Angebote dieses Anbieters
        </span>
      </Button>

      <Modal
        title={<h2>{association.data ? association.data.name : <Spin />}</h2>}
        visible={visible}
        footer={null}
        width="800px"
        onOk={() => handleVisible(false)}
        onCancel={() => handleVisible(false)}
      >
        {association.data ? (
          <>
            <p className="m-0">
              <b>Adresse des Anbieters:</b> {association.data.street},{" "}
              {association.data.zipCode} {association.data.city}
            </p>
            <p className="m-0">
              <b>Ansprechpartner:</b> {association.data.contactName}
            </p>
            <p className="m-0">
              <a href="#">
                <PhoneFilled /> {association.data.phone}
              </a>
            </p>
            <p className="m-0">
              <a href={`mailto:${association.data.email}`}>
                <MailFilled /> {association.data.email}
              </a>
            </p>
            {(association.data.website) ?
              <p className="m-0">
                <a
                  href={association.data.website}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <HomeFilled /> {association.data.website}
                </a>
              </p>
            : null }
            <p className="m-0 topspacing">
              <MedicineBoxOutlined />
              {`  `} Alle Angebote des Anbieters:
            </p>
            <List
              itemLayout="horizontal"
              dataSource={getFormatedData()}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={`${item.location.name}, ${item.location.street}, ${item.location.zipCode} ${item.location.city}`}
                    description={item.groupData.map((e, key) => (
                      <div key={`description-${key}`}>
                        <TimeTable
                          trainingTimes={e.trainingTimes}
                          mainIndicator={t('mainIndicators.' + e.mainIndicator)}
                          offerAdditionalInfo={e.offerAdditionalInfo}
                          t={t}
                        />
                      </div>
                    ))}
                  />
                </List.Item>
              )}
            />
          </>
        ) : (
          <Spin />
        )}
      </Modal>
    </div>
  );
};

export default RehabCoursesModal;
