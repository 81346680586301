import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const appLocales = ['de', 'en']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "de",
    lng: 'de',
    whitelist: appLocales,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    translation: {
      returnEmptyString: "",
    },
  });

export default i18n;
