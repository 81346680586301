import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import L from "leaflet";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, Marker, Popup, withLeaflet, TileLayer } from "react-leaflet";
import { Card, Collapse, Spin, Typography } from "antd";
import moment from "moment";
import {
  PhoneFilled,
  MailFilled,
  HomeFilled,
  CalendarFilled,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import RehabCoursesModal from "./components/RehabCoursesModal";
import { ReactLeafletSearch } from "react-leaflet-search";
import { GoogleLayer } from "react-leaflet-google-v2";
const { Text } = Typography;
const { Panel } = Collapse;

var customIcon = L.icon({
  iconUrl: "pin.png",
  iconSize: [38, 38], // size of the icon
  iconAnchor: [22, 33], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

const MapApp = () => {
  const [locations, handleLocations] = useState({ data: null, err: false });

  const { t } = useTranslation("translation");
  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = async () => {
    try {
      const res = await axios.get("/locations?rehab_trainings_null=false&rehab_trainings.association_null=false&latitude_null=false");
      handleLocations({ data: res.data, err: false });
    } catch (err) {
      handleLocations({ data: null, err });
    }
  };

  const getCenter = () => {
    let lat = "54.2";
    let long = "9.7";
    return [lat, long];
  };

  if (locations.data) {
    const ReactLeafletSearchComponent = withLeaflet(ReactLeafletSearch);

    /* Google layer
      <GoogleLayer
        googlekey={"AIzaSyDjjbfDz8LVj2mq8CZ2Dvk_dx01rWYkiMA"}
        maptype={"ROADMAP"}
      />
    */
    return (
      <div className="h-full">
        <Map center={getCenter()} zoom={9} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ReactLeafletSearchComponent
            inputPlaceholder="Suche..."
            position="topleft"
            provider="OpenStreetMap"
            providerOptions={{ region: "de" }}
            showMarker={false}
            openSearchOnLoad={true}
          />

          <MarkerClusterGroup>
            {locations.data.length
              ? locations.data.map((el, key) => {
                  if (el.latitude && el.longitude) {
                    return (
                      <Marker
                        icon={customIcon}
                        position={[el.latitude, el.longitude]}
                        key={key + "marker"}
                      >
                        <Popup>
                          <Card bordered={false}>
                            <h2 className="m-0 text-blue">{el.name}</h2>
                            <h4>
                              {el.street}
                              <br />
                              {el.zipCode} {el.city}
                            </h4>
                            <h3>
                              <Text strong>Reha-Angebote:</Text>
                            </h3>

                            <Collapse accordion key={"collapse"}>
                              {el.rehab_trainings
                                ? el.rehab_trainings.map((e, key) => (
                                    e.association
                                    ?
                                    <Panel
                                      header={`${e.association.name}, ${t(
                                        'mainIndicators.' + e.mainIndicator
                                      )}${(e.offer) ? ' ,' + e.offer : ''}${(e.offerAdditionalInfo) ? ' (' + e.offerAdditionalInfo + ')' : ''}`}
                                      key={`${key + 1}`}
                                    >
                                      <h4>{e.rehabSportsType}</h4>        
                                      {e.offerAdditionalInfo && (
                                        <Fragment>
                                          <h4>
                                            <Text strong>Zusatzinfo:</Text>{" "}
                                            {e.offerAdditionalInfo}
                                          </h4>
                                          <br />
                                        </Fragment>
                                      )}
                                      <p className="m-0">
                                        <CalendarFilled />
                                        <Text strong> Zeiten:</Text>{" "}
                                      </p>
                                      {e?.trainingTimes?.map((time, key) => (
                                        <p className="m-0" key={"time" + key}>
                                          <span className="capitalize bold">
                                            {t(time.weekDay)}:
                                          </span>{" "}
                                          <span>
                                            {" "}
                                            {moment(
                                              time.start,
                                              "HH:mm:ss"
                                            ).format("HH:mm")}
                                            -
                                            {moment(time.end, "HH:mm:ss").format(
                                              "HH:mm"
                                            )}
                                          </span>
                                          {(time.participants) ?
                                            <span>
                                              {`, ${(time.participants)}`}
                                            </span>
                                          : null}
                                        </p>
                                      ))}
                                      <br />
                                      <p className="m-0">
                                        <Text>
                                          Ansprechpartner:{" "}
                                          {e.association.contactName}
                                        </Text>{" "}
                                      </p>
                                      <p className="m-0">
                                        <a href="#">
                                          <PhoneFilled /> {e.association.phone}
                                        </a>
                                      </p>
                                      <p className="m-0">
                                        <a href={`mailto:${e.association.email}`}>
                                          <MailFilled /> {e.association.email}
                                        </a>
                                      </p>
                                      {(e.association.website) ?
                                        <p className="m-0">
                                          <a
                                            href={e.association.website}
                                            target="_blank"
                                            rel={"noreferrer"}
                                          >
                                            <HomeFilled /> {e.association.website}
                                          </a>
                                        </p>
                                      : null}
                                      <RehabCoursesModal
                                        id={e.association.id}
                                        t={t}
                                      />
                                    </Panel>
                                    : null
                                    )
                                  )
                                : null}
                            </Collapse>
                          </Card>
                        </Popup>
                      </Marker>
                    );
                  }
                  return null;
                })
              : null}
            </MarkerClusterGroup>
        </Map>
      </div>
    );
  }
  return (
    <div className="loading">
      <Spin size="large" />
    </div>
  );
};

export default MapApp;
