import moment from "moment";
import { Table, Tooltip } from "antd";
import { uniqueId } from "lodash";

export const TimeTable = ({
  trainingTimes,
  mainIndicator,
  offerAdditionalInfo,
  t,
}) => (
  <Table
    rowKey={() => uniqueId()}
    pagination={false}
    showHeader={false}
    dataSource={trainingTimes}
    size="small"
    columns={[
      {
        title: "Wochentag",
        dataIndex: "weekDay",
        render: (text) => t(text),
        key: "weekDay",
      },
      {
        title: "Beginn",
        dataIndex: "start",
        render: (text) => moment(text, "HH:mm:ss").format("HH:mm"),
        key: "start",
      },
      {
        title: "Ende",
        dataIndex: "end",
        render: (text) => moment(text, "HH:mm:ss").format("HH:mm"),
        key: "end",
      },
      {
        title: "Hauptindikator",
        render: () => (
          <Tooltip title={mainIndicator}>
            <span>{mainIndicator}</span>
          </Tooltip>
        ),
        key: "mainIndicator",
        ellipsis: true,
      },
      {
        title: "Angebotsdetails",
        width: 190,
        dataIndex: "offerAdditionalInfo",
        key: "offerAdditionalInfo",
        render: (text) => offerAdditionalInfo
      },
      {
        title: "Hinweis",
        dataIndex: "participants",
        key: "participants",
        render: (text) => text
      },
    ]}
  />
);
